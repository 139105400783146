import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

export const Feedback = () => {
  const theme = useTheme();
  return (
    <Stack justifyContent={"center"} alignItems={"center"} gap={2} padding={3}>
      <Box textAlign={"center"}>
        <h1>Customer feedback</h1>
        <Typography variant="body2" maxWidth={"500px"} textAlign={"center"}>
          This is what our customers say about us
        </Typography>
      </Box>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "stretch",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <Card sx={{ maxWidth: 345, minWidth: 200 }}>
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Box>
                <Typography gutterBottom variant="h5" component="div">
                  Great finish, like a new garden
                </Typography>
                <Typography variant="body2" component="div">
                  Jen & Tim - Bulwell
                </Typography>
              </Box>
              <Box sx={{ color: "gold" }}>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Box>
              <Typography variant="body2" color="text.secondary">
                A seriously good job from these guys, considering the
                constraints around our garden. But honestly, they did such a
                great job cleaning it up. It looks so so smart now, it feels
                like a new house. Would highly recommend!
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            maxWidth: 345,
            minWidth: 200,
            backgroundColor: theme.palette.primary.lighter,
          }}
        >
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Box>
                <Typography gutterBottom variant="h5" component="div">
                  Amazing extension!
                </Typography>
                <Typography variant="body2" component="div">
                  Frank - West Bridgford
                </Typography>
              </Box>
              <Box sx={{ color: "gold" }}>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Box>
              <Typography variant="body2" color="text.secondary">
                Dom and his team took on our massive extension project and did a
                superb job of it. There was some challenges but they handled it
                really well and made sure everyhing was done properly, and they
                consulted us for every decision and kept us well informed on
                progress. Well prices as well. We're incredibly happy with the
                result!
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
    </Stack>
  );
};
