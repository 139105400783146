import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";

// sales stuff: Your AI transformation starts with one small step. Reach out to us and start your journey today.

export const AboutUs = () => {
  const theme = useTheme();
  return (
    <Box sx={{ padding: 2 }}>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item sm={2} md={2} sx={{ paddingRight: 2 }}></Grid>
        <Grid
          item
          sm={8}
          md={8}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <IconButton component={Link} to="/">
              <ArrowBackIcon />
            </IconButton>
            <h1>About us</h1>
          </Box>
          <Card
            sx={{
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2rem",
              width: "100%",
            }}
          >
            <CardContent padding={0}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  backgroundColor: theme.palette.background.paper,
                  width: "100%",
                  gap: "2rem",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h3">DW Building Services</Typography>
                    <IconButton
                      href="https://www.facebook.com/DominicWaterfieldBuildingServicesLtd/"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </IconButton>
                  </Box>
                  <Typography>
                    We are a small team of builders who always aim to bring to
                    life our customer's vision and leave them satisfied.
                  </Typography>
                  <Typography>
                    Our journey started in 2018 where the founder, Dominic
                    Waterfield, embarks on this journey to run his own building
                    firm. After many years in the construction industry, working
                    on a variety of job ranging from shop fitting, residential
                    buildings to large railway and power projects, Dom decided
                    to take the leap and start working with customers directly.
                    Since then, business has been growing steadily, even with
                    the covid period, and the team has welcomed new members and
                    regularly takes on apprentices to help them get started in
                    the industry.
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Typography variant="h3">Our values</Typography>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Vision
                    </span>{" "}
                    - We aim to understand our customer's vision and accompany
                    them to define the best solution to suit their needs
                  </Typography>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Transparency
                    </span>{" "}
                    - We are clear in our costing and in the external services
                    we use
                  </Typography>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Quality
                    </span>{" "}
                    - We always deliver a high quality and durable product
                  </Typography>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Respect
                    </span>{" "}
                    - We treat everyone with respect and are mindful of your
                    space and preferences
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Typography variant="h3">Our mission</Typography>
                  <Typography>
                    We want to become the go-to company for anyone looking for
                    quality building services within the Nottingham area. And we
                    believe there's a need for trustworthy reliable builders who
                    provide a clear and realistic assessment and price.
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Typography variant="h3">Our People</Typography>
                  <Typography>
                    At DWBS, we pride ourselves on fostering a tight-knit team
                    driven by passion, expertise and quality. At the helm is our
                    founder, Dominic, whose entrepreneurial spirit and
                    unwavering dedication set the tone for our organization. He
                    is supported by Tim and Jay.
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "2rem",
                    }}
                  >
                    <Card sx={{ width: 200 }}>
                      <CardMedia
                        component="img"
                        height="200"
                        width="200"
                        image="/dom.jpg"
                        alt="ceo image"
                      />
                      <Divider />
                      <CardContent>
                        <Typography>Dominic</Typography>
                      </CardContent>
                    </Card>
                    <Card sx={{ width: 200 }}>
                      <CardMedia
                        component="img"
                        height="200"
                        width="200"
                        image="/tim.jpg"
                        alt="dev image"
                      />
                      <Divider />
                      <CardContent>
                        <Typography>Tim</Typography>
                      </CardContent>
                    </Card>
                    <Card sx={{ width: 200 }}>
                      <CardMedia
                        component="img"
                        height="200"
                        width="200"
                        image="/apprentice.jpg"
                        alt="salesman image"
                      />
                      <Divider />
                      <CardContent>
                        <Typography>Jay</Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  <Typography>
                    Complementing their leadership are our talented labour and
                    apprentices, who we support in developing varied building
                    skills and making sure we support them in identifying and
                    dveloping their strength.
                  </Typography>
                  <Typography>
                    Together, our diverse team forms the backbone of our
                    company, united by a shared commitment to delivering
                    excellent work at realistic prices.
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid
        item
        sm={2}
        md={2}
        sx={{
          width: "100%",
        }}
      ></Grid>
    </Box>
  );
};
