import { Box, Button, Typography } from "@mui/material";
// import { useTheme } from "@mui/material";

export const Hero = () => {
  // const theme = useTheme();
  const backgroundImageUrl = "/home-hero.jpg";

  const containerStyle = {
    minHeight: "600px",
    maxHeight: "800px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backgroundImageUrl})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "flex-start",
    color: "#ffffff",
  };

  return (
    <Box style={containerStyle}>
      <Box
        pl={"3rem"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          // maxWidth: "800px",
          width: "100%",
          padding: "2rem",
          backgroundColor: "rgb(169,169,169, 0.5)",
        }}
      >
        <Typography fontSize={32} fontWeight={"bold"}>
          Your local specialist for all your building and renovation needs
        </Typography>
        <Box style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
          <Button variant={"contained"} color={"secondary"}>
            Explore our past projects
          </Button>
          <Button variant={"contained"} href="mailto:domwaterfield@gmail.com">
            Request a quote
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
