import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";

const services = [
  {
    title: "Structural works",
    areas: ["Wood frames", "Metal frames", "Brick walls", "Insulation"],
    examples: [
      "Build a house extension",
      "Build a front porch",
      "Convert a loft",
      "Add a roof dormer",
    ],
    storyPath: "/stories/structural",
  },
  {
    title: "Internal works",
    areas: ["Wall works", "Flooring", "Heating", "Insulation"],
    examples: [
      "Remove an internal wall",
      "Plaster and ",
      "Install wood flooring",

      "Upgrade a heating installation",
    ],
    storyPath: "/stories/internal",
  },
  {
    title: "Concrete work",
    areas: ["Foundations", "Concrete slabs", "Retaining walls"],
    examples: [
      "Build foundations for a side building",
      "Pour a garage base",
      "Build a garden retaining wall",
    ],
    storyPath: "/stories/concrete",
  },
  {
    title: "Stones and brickwork",
    areas: ["Stone walls", "Paving", "Tiles and stones walkways"],
    examples: [
      "Build new patios",
      "Upgrade garden walkways",
      "Pave a parking space",
      "Renovate stone walls",
    ],
    storyPath: "/stories/stones",
  },
  {
    title: "External works",
    areas: [
      "Gates",
      "Fences",
      "Garages",
      " Wood structures",
      "Garden structures",
    ],
    examples: [
      "Build a custom shed",
      "Install custom gate and fences",
      "Build a bespoke gazebos",
      "Build a car port",
      "Plant beds",
    ],
    storyPath: "/stories/external",
  },
];
export const Products = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item sm={2} md={2} sx={{ paddingRight: 2 }}></Grid>
          <Grid
            item
            sm={8}
            md={8}
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <IconButton component={Link} to="/">
                <ArrowBackIcon />
              </IconButton>
              <h1>Services</h1>
            </Box>
            <Card
              sx={{
                backgroundColor: theme.palette.background.paper,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "2rem",
                width: "100%",
              }}
            >
              <CardContent padding={0}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    backgroundColor: theme.palette.background.paper,
                    width: "100%",
                    gap: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography variant="h3">Our specialties</Typography>
                    <Typography>
                      There's a whole range of services that we can provide to
                      support you with your internal or external renovation
                      needs. Our strength is in foundation works, brick and
                      stone works, and all structural matters, and we will
                      partner up with other local firms to support us if the
                      project requires specific skills and certifications, for
                      example with electrical, gas or plumbing matters, or for
                      specific designs and finishes.
                    </Typography>
                    <Typography>
                      These are areas and examples of work we excel at and would
                      love to support you with:
                    </Typography>
                    <Stack>
                      {services.map((item, index) => (
                        <Box
                          key={`service-${index}`}
                          padding={"1rem 0"}
                          borderTop={"1px solid grey"}
                        >
                          <Typography variant="h4">{item.title}</Typography>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"1rem"}
                            padding={"1rem 0"}
                          >
                            <Box>
                              <Typography variant="h5">Work type</Typography>
                              {item.areas.map((a) => (
                                <Typography key={a}>{a}</Typography>
                              ))}
                            </Box>
                            <Box>
                              <Typography variant="h5">Examples</Typography>
                              {item.examples.map((e) => (
                                <Typography key={e}>{e}</Typography>
                              ))}
                            </Box>
                          </Box>
                          {!!item.storyPath && (
                            <Button
                              variant="outlined"
                              onClick={() => navigate(item.storyPath)}
                            >
                              Explore {item.title}
                            </Button>
                          )}
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          item
          sm={2}
          md={2}
          sx={{
            width: "100%",
          }}
        ></Grid>
      </Box>
    </>
  );
};
