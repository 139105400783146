import { Box, ListSubheader, MenuItem, Select } from "@mui/material";
import { useRef } from "react";

export const SelectList = ({ list, selectedItem, setSelectedItem }) => {
  const selectRef = useRef(null);

  console.log("selected item sent to selectList", selectedItem);
  const handleChange = (event) => {
    console.log("event", event.target);
    setSelectedItem(event.target.value);
  };

  return (
    <Box ref={selectRef}>
      <Select
        defaultValue={"Select"}
        id="grouped-select"
        onChange={handleChange}
        renderValue={(selectedItem) => {
          console.log("rendering", selectedItem);
          return selectedItem?.name ?? "Select";
        }}
      >
        <MenuItem disabled value={"Select"}>
          Select
        </MenuItem>
        {list?.reduce((acc, p) => {
          if (!!p.title) {
            acc.push(
              <ListSubheader
                key={`subheader-${p.title}`}
                style={{ borderTop: "1px solid grey" }}
              >
                {p.title}
              </ListSubheader>
            );
            acc.push(
              ...p.children.map((pl) => (
                <MenuItem
                  key={pl.id}
                  value={pl}
                  selected={selectedItem.name === pl.name}
                >
                  {pl.name}
                </MenuItem>
              ))
            );
          } else {
            acc.push(
              <MenuItem
                key={p.id}
                value={p}
                selected={selectedItem?.name && selectedItem.name === p.name}
                style={{ borderTop: "1px solid grey" }}
              >
                {p.name}
              </MenuItem>
            );
          }

          return acc;
        }, [])}
      </Select>
    </Box>
  );
};
