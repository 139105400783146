// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';

import users from './usersSlice';


const reducers = combineReducers({
  menu,
  users,
 
});

export default reducers;
