export const ThemeMode = {
    LIGHT : 'light',
    DARK : 'dark'
  }
  
  export const MenuOrientation = {
    VERTICAL : 'vertical',
    HORIZONTAL : 'horizontal'
  }
  
  export const ThemeDirection = {
    LTR : 'ltr',
    RTL : 'rtl'
  }
