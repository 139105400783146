import { Stack } from "@mui/material";
import { Feedback } from "sections/Feedback";
import { Hero } from "sections/Hero";
import { HeroProduct } from "sections/HeroProduct";
import { SuccessHome } from "sections/SuccessHome";
// import { Why } from "sections/Why";

export const Home = () => {
  return (
    <>
      <Stack id={"home-stack"}>
        <Hero />
        <SuccessHome />
        <Feedback />
        <HeroProduct />
      </Stack>
    </>
  );
};
