import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

export const SuccessHome = () => {
  const theme = useTheme();
  return (
    <Stack justifyContent={"center"} alignItems={"center"} gap={2} padding={3}>
      <Box textAlign={"center"}>
        <h1>Past projects</h1>
        <Typography variant="body2" maxWidth={"500px"} textAlign={"center"}>
          Whether you need a hand to repair a gate, plan to renovate a room, or
          you are thinking of building a 2-story extension, DWBS is perfect to
          help you achieve your vision. We also work with small businesses and
          can help create their dream space.
        </Typography>
      </Box>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "stretch",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <Card sx={{ maxWidth: 345, minWidth: 200 }}>
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Large Extension
              </Typography>
              <Typography variant="body2" component="div">
                West Bridgford
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Extending a 2-story house with a front, side and full back
                extension
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  to="/stories/structural"
                  component={Link}
                >
                  View project
                </Button>
              </Box>
            </CardContent>
            <CardMedia
              component="img"
              height="200"
              image="/extension-story.jpg"
              alt="knowledge-base image"
            />
          </CardActionArea>
        </Card>
        <Card
          sx={{
            maxWidth: 345,
            minWidth: 200,
            backgroundColor: theme.palette.primary.lighter,
          }}
        >
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Shop refurbishment
              </Typography>
              <Typography variant="body2" component="div">
                Nottingham
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Full shop renovation, internal decoration and fitting a food and
                drinks station
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  to="/stories/internal"
                  component={Link}
                >
                  View details
                </Button>
              </Box>
            </CardContent>
            <CardMedia
              component="img"
              height="200"
              image="/shop-story.jpg"
              alt="report generation image"
            />
          </CardActionArea>
        </Card>
        <Card sx={{ maxWidth: 345, minWidth: 200 }}>
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Outdoor works
              </Typography>
              <Typography variant="body2" component="div">
                Nottingham
              </Typography>
              <Typography variant="body2" color="text.secondary">
                From patio steps to full garden refurbishment, including
                terrasses, gates and stone works
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  to="/stories/2074"
                  component={Link}
                >
                  Explore options
                </Button>
              </Box>
            </CardContent>
            <CardMedia
              component="img"
              height="200"
              image="/outdoor-story.jpg"
              alt="management image"
            />
          </CardActionArea>
        </Card>
      </Stack>
    </Stack>
  );
};
