// material-ui
import { alpha, createTheme } from "@mui/material/styles";

// third-party
import { presetDarkPalettes, presetPalettes } from "@ant-design/colors";

// project import
import ThemeOption from "./theme";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode = "LIGHT", presetColor) => {
  const colors = mode === "dark" ? presetDarkPalettes : presetPalettes;

  let greyPrimary = [
    "#ffffff",
    "#fafafa",
    "#f5f5f5",
    "#f0f0f0",
    "#d9d9d9",
    "#bfbfbf",
    "#8c8c8c",
    "#595959",
    "#262626",
    "#141414",
    "#000000",
  ];
  let greyAscent = ["#fafafa", "#bfbfbf", "#434343", "#1f1f1f"];
  let greyConstant = ["#fafafb", "#e6ebf1"];

  if (mode === "dark") {
    greyPrimary = [
      "#000000",
      "#141414",
      "#1e1e1e",
      "#595959",
      "#8c8c8c",
      "#bfbfbf",
      "#d9d9d9",
      "#f0f0f0",
      "#f5f5f5",
      "#fafafa",
      "#ffffff",
    ];
    greyAscent = ["#fafafa", "#bfbfbf", "#434343", "#1f1f1f"];
    greyConstant = ["#121212", "#d3d8db"];
  }
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors, presetColor, mode);

  return createTheme({
    palette: {
      mode,
      common: {
        black: "#000",
        white: "#fff",
      },
      ...paletteColor,
      text: {
        primary:
          mode === "dark"
            ? alpha(paletteColor.grey[900], 0.87)
            : paletteColor.grey[700],
        secondary:
          mode === "dark"
            ? alpha(paletteColor.grey[900], 0.45)
            : paletteColor.grey[500],
        disabled:
          mode === "dark"
            ? alpha(paletteColor.grey[900], 0.1)
            : paletteColor.grey[400],
      },
      action: {
        disabled: paletteColor.grey[300],
      },
      divider:
        mode === "dark"
          ? alpha(paletteColor.grey[900], 0.05)
          : paletteColor.grey[200],
      background: {
        paper: mode === "dark" ? paletteColor.grey[100] : paletteColor.grey[0],
        default:
          mode === "dark" ? paletteColor.grey.A50 : paletteColor.grey[200],
      },
      header: {
        default: "#ffffff", //paletteColor.grey.A700, /!\ Not working!! /!\
        logo: paletteColor.grey.A50,
        text: "#000000", //paletteColor.grey.A50, WORKING :)
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            "--fill-color":
              mode === "dark" ? "rgb(125, 114, 211)" : paletteColor.grey[700], //or #655ac8?
            "--stroke-color":
              mode === "dark" ? "rgb(125, 114, 211)" : paletteColor.grey[700],
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          scrollbarColor: `${paletteColor.grey[300]} ${
            mode === "dark" ? paletteColor.grey[100] : paletteColor.grey[0]
          } `,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor:
              mode === "dark" ? paletteColor.grey[100] : paletteColor.grey[0],
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: paletteColor.grey[300],
            minHeight: 24,
            border: `3px solid ${
              mode === "dark" ? paletteColor.grey[100] : paletteColor.grey[0]
            }`,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: paletteColor.grey[500], //accent colour
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: paletteColor.grey[500], //"#3c64d0",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: paletteColor.grey[500],
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor:
              mode === "dark" ? paletteColor.grey[100] : paletteColor.grey[0],
          },
        },
      },
      // ...
    },
  });
};

export default Palette;
