import { ExpandLess, ExpandMore } from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/Work";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const SideList = ({ list, selectedItem, setSelectedItem }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(null);

  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      <List
        sx={{
          padding: "0",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {list.reduce((acc, p) => {
          if (!!p.title) {
            acc.push(
              <ListItemButton
                key={`subheader-${p.title}`}
                style={{
                  borderTop: "1px solid grey",
                  backgroundColor: theme.palette.background.paper,
                }}
                onClick={() => {
                  if (!open || open !== p.section) {
                    setOpen(p.section);
                  } else {
                    setOpen(null);
                  }
                }}
              >
                <ListItemText primary={p.title} />
                {open === p.section ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            );

            acc.push(
              <Collapse
                key={p.title}
                in={open === p.section}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {p.children.map((pl) => (
                    <ListItemButton
                      key={`subitem${pl.id}`}
                      selected={selectedItem.name === pl.name}
                      sx={{ pl: 4, gap: "0.5rem" }}
                      onClick={() => {
                        setSelectedItem(pl);
                      }}
                    >
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary={pl.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            );
          } else {
            acc.push(
              <ListItemButton
                key={`item${p.id}`}
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "4px",
                  backgroundColor: theme.palette.background.paper,
                }}
                selected={selectedItem?.name === p.name}
                sx={{ pl: 3, gap: "0.5rem" }}
                onClick={() => {
                  setSelectedItem(p);
                }}
              >
                <ListItemText primary={p.name} />
              </ListItemButton>
            );
          }

          return acc;
        }, [])}
      </List>
    </Box>
  );
};
