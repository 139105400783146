import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { SelectList } from "components/lists/SelectList";
import { SideList } from "components/lists/SideList";
import { useState, useEffect } from "react";
import { Outlet, useNavigate, Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const storiesMenu = [
  {
    id: "structural",
    name: "Structural works",
    section: "stories",
  },
  {
    id: "internal",
    name: "Internal works",
    section: "stories",
  },
  {
    id: "concrete",
    name: "Concrete work",
    section: "stories",
  },
  {
    id: "external",
    name: "External works",
    section: "stories",
  },
  {
    id: "stone",
    name: "Stone work",
    section: "stories",
  },
];

export const Stories = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { id } = useParams();
  window.scrollTo(0, 0);

  //for the selected item - for both menu types
  const [selectedItem, setSelectedItem] = useState(storiesMenu[0]);

  useEffect(() => {
    const selected = storiesMenu.filter((item) => item.id === id)[0];
    setSelectedItem(selected);
  }, [id]);

  const handleChange = (item) => {
    setSelectedItem(item);
    const url = window.location.pathname;
    const splitUrl = url.split("/");
    const newUrl = `${splitUrl.slice(0, 2).join("/")}/${item.id}`;
    navigate(`${newUrl}`);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item sm={2} md={2} sx={{ paddingRight: 2 }}></Grid>
        <Grid item sm={10} md={10}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <IconButton component={Link} to="/">
              <ArrowBackIcon />
            </IconButton>
            <h1>Projects</h1>
          </Box>
        </Grid>
      </Grid>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item sm={2} md={2} sx={{ paddingRight: 2, marginBottom: "1rem" }}>
          {matchesXs ? (
            <SelectList
              list={storiesMenu}
              selectedItem={selectedItem}
              setSelectedItem={handleChange}
            />
          ) : (
            <SideList
              list={storiesMenu}
              selectedItem={selectedItem}
              setSelectedItem={handleChange}
            />
          )}
        </Grid>
        <Grid
          item
          sm={10}
          md={10}
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: "100%",
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};
