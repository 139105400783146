// import "./App.css";
import Routes from "./routes";
import ThemeCustomization from "themes";

export default function App() {
  return (
    <ThemeCustomization>
      <Routes />
    </ThemeCustomization>
  );
}
