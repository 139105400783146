import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { UnderConstruction } from "components/UnderConstruction";

export const Document = () => {
  const { id } = useParams();
  return (
    <Box sx={{ padding: 2 }}>
      <h1>Document</h1>
      <h3>{id}</h3>
      <UnderConstruction />
    </Box>
  );
};
