


const DigitalOceanTheme = (colors, mode)=> {
  const { grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  let primaryColors = ['#0080FF', '#0066FF', '#004CFF', '#0033FF', '#0019FF', '#0000FF', '#0000CC', '#000099', '#000066', '#000033'];
  let errorColors = ['#FF3333', '#FF1A1A', '#FF0000', '#E60000', '#CC0000'];
  let warningColors = ['#FFCC00', '#FFB300', '#FF9900', '#FF8000', '#FF6600'];
  let infoColors = ['#33CCFF', '#1AB2FF', '#0099FF', '#0080FF', '#0066FF'];
  let successColors = ['#33CC33', '#1AB21A', '#009900', '#008000', '#006600'];

  if (mode === 'dark') {
    primaryColors = ['#004C99', '#003366', '#001A33', '#000000', '#000000', '#000033', '#000066', '#000099', '#0000CC', '#0000FF'];
    errorColors = ['#990000', '#800000', '#660000', '#4D0000', '#330000'];
    warningColors = ['#994C00', '#663300', '#331A00', '#000000', '#000000'];
    infoColors = ['#004C99', '#003366', '#001A33', '#000000', '#000000'];
    successColors = ['#004C00', '#003300', '#001A00', '#000000', '#000000'];
  }

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500],
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText
    },
    grey: greyColors
  };
};

export default DigitalOceanTheme;