import { Opacity } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useWindowDimensions } from "utils/mediaQuery";

export const HeroProduct = () => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const backgroundImageUrl = "/product-hero.jpg";

  const isMobile = width < 768;

  const desktopContainerStyle = {
    minHeight: "400px",
    backgroundSize: "cover",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backgroundImageUrl})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#ffffff",
  };

  const mobileContainerStyle = {
    minHeight: "200px",
    maxHeight: "400px",
    backgroundSize: "cover",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backgroundImageUrl})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#ffffff",
  };

  return (
    <Box style={isMobile ? mobileContainerStyle : desktopContainerStyle}>
      <Box
        pl={isMobile ? "0" : "3rem"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: isMobile ? "center" : "left",
          gap: "2rem",
          width: isMobile ? "100%" : "auto",
        }}
      >
        <Typography fontSize={30} fontWeight={"bold"}>
          <span>Contact us for a quote </span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            justifyContent: "center",
            alignItems: isMobile ? "center" : "left",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box>
            <Button
              variant={"contained"}
              color="success"
              href="tel:07858151520"
            >
              Call us
            </Button>
          </Box>
          <Box>
            <Button variant={"contained"} href="mailto:domwaterfield@gmail.com">
              Send email
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
