import { Box, Typography } from "@mui/material";
import { storiesMenu } from "pages/stories";
import { useParams } from "react-router-dom";
import extensionLarge from "../assets/images/projects/dw-extension-15.jpg";
import extensionBack from "../assets/images/projects/dw-extension-4.jpg";
import extensionStathern from "../assets/images/projects/dw-stathern-14.jpg";
import extensionSmall from "../assets/images/projects/dw-patio-4.jpg";
import shop1 from "../assets/images/projects/dw-coffeeshop-1.jpg";
import shop2 from "../assets/images/projects/dw-coffeeshop-2.jpg";
import shop3 from "../assets/images/projects/dw-coffeeshop-3.jpg";
import shop4 from "../assets/images/projects/dw-coffeeshop-4.jpg";
import shop5 from "../assets/images/projects/dw-coffeeshop-5.jpg";
import concreteDrive from "../assets/images/projects/dw-concrete-drive.jpg";
import pavingFront from "../assets/images/projects/dw-paving-5.jpg";
import sideWall from "../assets/images/projects/dw-side-wall.jpg";
import edgeWall from "../assets/images/projects/dw-walltop-1.jpg";
import pavingGarden from "../assets/images/projects/dw-sneinton-garden-4.jpg";
import stoneWall from "../assets/images/projects/dw-paving-4.jpg";
import circularPatio from "../assets/images/projects/dw-circular-outdoor.jpg";
import terrasse from "../assets/images/projects/dw-terrasse.jpg";
import steps from "../assets/images/projects/dw-outdoor-steps.jpg";
import patioFloor from "../assets/images/projects/dw-patio-1.jpg";
import curvedWall from "../assets/images/projects/dw-curved-wall.jpg";

// const storiesContent = {
//   structural: {
//     title: "Structural projects",
//     text1: "House extension projects",
//     image1: "",
//     text2: "Some text for the second paragraph",
//   },
// };
export const Story = () => {
  const { id } = useParams();
  // const storyContent = storiesContent[id];

  return (
    <Box
      sx={{ padding: 2, display: "flex", flexDirection: "column", gap: "2rem" }}
    >
      {(!id || id === "structural") && (
        <>
          <Typography variant="h3">Structural projects</Typography>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography>House extensions</Typography>

            <img width={"100%"} src={extensionBack}></img>
            <img width={"100%"} src={terrasse}></img>

            <img width={"100%"} src={extensionStathern}></img>
            <img width={"100%"} src={extensionSmall}></img>
          </Box>
        </>
      )}
      {id === "internal" && (
        <>
          <Typography variant="h3">Shop refurbishment</Typography>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography>Main rooms and bathroom</Typography>

            {/* <img width={"100%"} src={extensionLarge}></img> */}
            <img width={"100%"} src={shop1}></img>

            <img width={"100%"} src={shop3}></img>
            <img width={"100%"} src={shop4}></img>
            <img width={"100%"} src={shop5}></img>
            <img width={"100%"} src={shop2}></img>
          </Box>
        </>
      )}
      {id === "concrete" && (
        <>
          <Typography variant="h3">Concrete works</Typography>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography>Concrete drive</Typography>

            {/* <img width={"100%"} src={extensionLarge}></img> */}
            <img width={"100%"} src={concreteDrive}></img>
            <Typography>Patios and terrasses</Typography>

            {/* <img width={"100%"} src={extensionLarge}></img> */}
            <img width={"100%"} src={patioFloor}></img>
          </Box>
        </>
      )}
      {id === "external" && (
        <>
          <Typography variant="h3">External works</Typography>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography>Terrasses & tiling</Typography>
            <img width={"100%"} src={terrasse}></img>
            <img width={"100%"} src={steps}></img>
            <Typography>Paving</Typography>

            <img width={"100%"} src={pavingFront}></img>
            <img width={"100%"} src={pavingGarden}></img>
            <Typography>Walls & fences</Typography>
            <img width={"100%"} src={curvedWall}></img>
            <img width={"100%"} src={sideWall}></img>
            <img width={"100%"} src={edgeWall}></img>
          </Box>
        </>
      )}
      {id === "stone" && (
        <>
          <Typography variant="h3">Stone works</Typography>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography>Stone walls</Typography>

            <img width={"100%"} src={stoneWall}></img>
            <Typography>Patios</Typography>
            <img width={"100%"} src={circularPatio}></img>
          </Box>
        </>
      )}
    </Box>
  );
};
