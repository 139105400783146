// project import
import MainLayout from "../layout/MainLayout";
import CommonLayout from "../layout/CommonLayout";
import { AboutUs } from "../pages/about";
import { Home } from "pages/home";
import { Contact } from "pages/contact";
import { Products } from "pages/products";
import { Documentation } from "pages/documentation";
import { Roadmap } from "sections/Roadmap";
import { Document } from "sections/Document";
import { UnderConstruction } from "components/UnderConstruction";
import { Stories } from "pages/stories";
import { Article } from "sections/Article";
import { Story } from "sections/Story";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "about",
          element: <AboutUs />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "services",
          element: <Products />,
        },
        {
          path: "stories",
          element: <Stories />,
          children: [
            {
              path: "",
              element: <Story />,
            },
            {
              path: ":id",
              element: <Story />,
            },
          ],
        },

        {
          path: "documentation",
          element: <Documentation />,
          children: [
            {
              path: "",
              element: <UnderConstruction />,
            },
            {
              path: "articles",
              element: <UnderConstruction />,
            },
            {
              path: "articles/:id",
              element: <Article />,
            },
            {
              path: "application",
              element: <UnderConstruction />,
            },
            {
              path: "application/:id",
              element: <Document />,
            },
            {
              path: "roadmap",
              element: <UnderConstruction />,
            },
            {
              path: "roadmap/:id",
              element: <Roadmap />,
            },
          ],
        },
      ],
    },

    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <AboutUs />,
        },
        // {
        //   path: "404",
        //   element: <MaintenanceError />,
        // },
        // {
        //   path: "500",
        //   element: <MaintenanceError500 />,
        // },
        // {
        //   path: "under-construction",
        //   element: <MaintenanceUnderConstruction />,
        // },
        // {
        //   path: "coming-soon",
        //   element: <MaintenanceComingSoon />,
        // },
      ],
    },
  ],
};

export default MainRoutes;
