import { Box } from "@mui/material";

import { UnderConstruction } from "components/UnderConstruction";

export const Roadmap = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <h1>Roadmap </h1>
      <h3>With release history and other features</h3>
      <UnderConstruction />
    </Box>
  );
};
