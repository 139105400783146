import { Link } from "react-router-dom";

// material-ui
import { Box, ButtonBase, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import { ReactComponent as LogoSmall } from "assets/images/logo/dw-logo-small-red-svg.svg";
import { ReactComponent as LogoSmallDark } from "assets/images/logo/dw-logo-for-dark-background.svg";
import { ReactComponent as LogoName } from "assets/images/logo/dw-name-black-svg.svg";

import { APP_DEFAULT_PATH } from "config";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isIcon, sx, to, contrast, darkBackground = false }) => {
  const theme = useTheme();

  const nameStyle = {
    font: "Roboto",
    fontWeight: "bold",
    fontSize: 24,
    color: !!darkBackground ? "white" : "black",
  };

  const fillColor = "#B31312";
  const strokeColor = fillColor;
  return (
    <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to}>
      {!!isIcon ? (
        <LogoSmall
          className={"MuiSvgIcon"}
          style={{
            fill: fillColor,
            stroke: strokeColor,
          }}
          height={60}
        />
      ) : (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"1rem"}
          alignItems={"center"}
        >
          {!!darkBackground ? (
            <LogoSmallDark
              style={{
                fill: fillColor,
                stroke: strokeColor,
              }}
              className={"MuiSvgIcon"}
              width={60}
              height={60}
            />
          ) : (
            <LogoSmall
              style={{
                fill: fillColor,
                stroke: strokeColor,
              }}
              className={"MuiSvgIcon"}
              width={60}
              height={60}
            />
          )}
          <Box>
            <Typography style={nameStyle}>DW Building Services</Typography>
          </Box>
        </Box>
      )}
    </ButtonBase>
  );
};

export default LogoSection;
