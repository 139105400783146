
import axios from 'axios';


const hostname = window.location.hostname;
const env = hostname.split('.')[0];
let uri;

if (env === "lnr") {
  uri = 'https://graphql.lnr.ai';
} else if (env === "localhost") {
  uri = 'http://localhost:4000';
} else {
  uri = `https://graphql-${env}.lnr.ai`;
}

const axiosServices = axios.create({ baseURL: uri });

axiosServices.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('serviceToken'); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;