import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Toolbar, Box, useMediaQuery } from "@mui/material";

// project import
import LogoSection from "components/logo";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));

  // header content
  const headerContent = useMemo(() => <HeaderDesktop />, []);
  const headerMobile = useMemo(() => <HeaderMobile />, []);

  // common header
  const mainHeader = (
    <Toolbar sx={{ justifyContent: "space-between" }}>
      <LogoSection to="/" contrast={true} />
      <Box display="flex">{!matchesXs ? headerContent : headerMobile}</Box>
    </Toolbar>
  );

  return (
    <Box
      sx={{
        borderBottom: `2px solid grey`,
        zIndex: 1200,
        width: "100%",
        backgroundColor: "#ffffff", //theme.palette.header.default, //needs to be the dark version
      }}
    >
      {mainHeader}
    </Box>
  );
};

export default Header;
