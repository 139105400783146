import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import construction from "assets/images/maintenance/under-construction-2.svg";
import { Link } from "react-router-dom";

export const UnderConstruction = () => {
  return (
    <Grid
      container
      spacing={4}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ py: 2 }}
    >
      <Grid item xs={12}>
        <Box sx={{ width: { xs: 300, sm: 480 } }}>
          <img
            src={construction}
            alt="mantis"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography align="center" variant="h1">
            Under Construction
          </Typography>
          <Typography
            color="textSecondary"
            align="center"
            sx={{ width: "75%" }}
          >
            Please check out this section later. We are doing some work on it
            right now and it will be available soon.
          </Typography>
          <Button component={Link} to={"/"} variant="contained">
            Back To Home
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
