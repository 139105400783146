import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { SelectList } from "components/lists/SelectList";
import { SideList } from "components/lists/SideList";
import { useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const sideMenu = [
  {
    id: "SS0",
    section: "articles",
    title: "Articles",
    children: [
      {
        id: "structural",
        name: "Article 1",
        section: "articles",
      },
      {
        id: "internal",
        name: "Article 2",
        section: "articles",
      },
    ],
  },
  {
    id: "D1",
    section: "application",
    title: "Documents",
    children: [
      {
        id: "4569871225",
        name: "Doc 1",
        section: "application",
      },
      {
        id: "4001001224",
        name: "Doc 2",
        section: "application",
      },
      {
        id: "2035654541",
        name: "Doc 3",
        section: "application",
      },
    ],
  },
  {
    id: "R2",
    name: "Roadmap",
    section: "roadmap",
  },
];

export const Documentation = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  //get the id from url
  const url = window.location.pathname;

  //for the selected item - for both menu types
  const [selectedItem, setSelectedItem] = useState("" | {});

  const handleChange = (item) => {
    setSelectedItem(item);
    const splitUrl = url.split("/");
    const newUrl = `${splitUrl.slice(0, 2).join("/")}/${item.section}/${
      item.id
    }`;
    navigate(`${newUrl}`);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item sm={2} md={2} sx={{ paddingRight: 2 }}></Grid>
        <Grid item sm={10} md={10}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <IconButton component={Link} to="/">
              <ArrowBackIcon />
            </IconButton>
            <h1>Resources</h1>
          </Box>
        </Grid>
      </Grid>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item sm={2} md={2} sx={{ paddingRight: 2 }}>
          {matchesXs ? (
            <SelectList
              list={sideMenu}
              selectedItem={selectedItem}
              setSelectedItem={handleChange}
            />
          ) : (
            <SideList
              list={sideMenu}
              selectedItem={selectedItem}
              setSelectedItem={handleChange}
            />
          )}
        </Grid>
        <Grid
          item
          sm={10}
          md={10}
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: "100%",
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};
