import { lazy } from "react";
import { Outlet } from "react-router-dom";

// material-ui
// import { styled } from "@mui/material/styles";
// import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import Header from "layout/Header";

const FooterBlock = lazy(() => import("../Footers/FooterBlock"));

// ==============================|| Loader ||============================== //

// const LoaderWrapper = styled("div")(({ theme }) => ({
//   position: "fixed",
//   top: 0,
//   left: 0,
//   zIndex: 2001,
//   width: "100%",
//   "& > * + *": {
//     marginTop: theme.spacing(2),
//   },
// }));

// const Loader = () => (
//   <LoaderWrapper>
//     <LinearProgress color="primary" />
//   </LoaderWrapper>
// );

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "100vh",
    }}
  >
    <Header />
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Outlet />
    </Box>
    <FooterBlock isFull={true} />
  </Box>
);

export default CommonLayout;
