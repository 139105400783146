import { Box } from "@mui/material";
import { UnderConstruction } from "components/UnderConstruction";
import { useParams } from "react-router-dom";

export const Article = () => {
  const { id } = useParams();
  return (
    <Box sx={{ padding: 2 }}>
      <h1>Article</h1>
      <h5>{id}</h5>
      <UnderConstruction />
    </Box>
  );
};
