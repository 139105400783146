import { Link } from "react-router-dom";
import { send } from "emailjs-com";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";

//enquiry content template: template_cj71txj
//service id: service_zhpu8is
//public key: RlVHoIKnMRzeiVd5a
//private key: Ta_DNAA6WIJjBLSA39Zz3

export const Contact = () => {
  const theme = useTheme();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [enquiryError, setEnquiryError] = useState(false);
  const [successEnquiry, setSuccessEnquiry] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setEmailError(false);
    setEnquiryError(false);
    setNameError(false);

    const regex = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

    const validEmail = regex.test(email);
    if (!validEmail) {
      setEmailError(true);
    }
    if (enquiry == "") {
      setEnquiryError(true);
    }
    if (name == "") {
      setNameError(true);
    }

    if (validEmail && enquiry && name) {
      const values = {
        name,
        email,
        message: enquiry,
      };
      send("service_zhpu8is", "template_cj71txj", values, "RlVHoIKnMRzeiVd5a")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessEnquiry(true);
          setName("");
          setEmail("");
          setEnquiry("");
        })
        .catch((err) => {
          console.log("FAILED...", err);
        });
    }
  };
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item sm={2} md={2} sx={{ paddingRight: 2 }}></Grid>
          <Grid
            item
            sm={8}
            md={8}
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <IconButton component={Link} to="/">
                <ArrowBackIcon />
              </IconButton>
              <h1>Contact us</h1>
            </Box>
            <Card
              sx={{
                backgroundColor: theme.palette.background.paper,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "2rem",
                width: "100%",
              }}
            >
              <CardContent padding={0}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    backgroundColor: theme.palette.background.paper,
                    width: "100%",
                    gap: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography variant="h3">We're here for you.</Typography>
                    <Typography>
                      Get in touch with us if you want to know more about our
                      products, want to book a visit for a quote or just have a
                      question about our services.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography variant="h4">By phone</Typography>
                    <Typography>
                      You can contact us directly by phone between 9.00am and
                      5.00pm.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <IconButton>
                        <PhoneIcon />
                      </IconButton>
                      <Typography fontWeight={"bold"}>07858 151520</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography variant="h4">By email</Typography>
                    <Typography>
                      You can email us anytime with details and supporting
                      documents to your enquiry.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <IconButton>
                        <MailIcon />
                      </IconButton>

                      <Typography fontWeight={"bold"}>
                        domwaterfield@gmail.com
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography variant="h4">Online form</Typography>
                    <Typography>
                      You can also contact us by using this online form.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label="Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                        variant="outlined"
                        color="secondary"
                        type="name"
                        sx={{ mb: 3 }}
                        fullWidth
                        value={name}
                        error={nameError}
                      />
                      <TextField
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        variant="outlined"
                        color="secondary"
                        type="email"
                        sx={{ mb: 3 }}
                        fullWidth
                        value={email}
                        error={emailError}
                      />
                      <TextField
                        id="enquiry-box"
                        label="Enquiry"
                        multiline
                        rows={4}
                        value={enquiry}
                        error={enquiryError}
                        onChange={(e) => setEnquiry(e.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        sx={{ mb: 3 }}
                      />
                      {!successEnquiry && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Box>
                  {successEnquiry && (
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                    >
                      We've successfully sent your enquiry to the building team.
                    </Alert>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          item
          sm={2}
          md={2}
          sx={{
            width: "100%",
          }}
        ></Grid>
      </Box>
    </>
  );
};
