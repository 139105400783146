// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
// third party
import { motion } from "framer-motion";

// types
import { ThemeDirection, ThemeMode } from "types/config";
import LogoSection from "components/logo";

//const dashImage = require.context('assets/images/landing', true);

// link - custom style
const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "&:active": {
    color: theme.palette.primary.main,
  },
}));

// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterBlock = ({ isFull }) => {
  const theme = useTheme();
  //const { presetColor } = useConfig();
  const textColor =
    theme.palette.mode === ThemeMode.DARK ? "text.primary" : "background.paper";

  return (
    <Stack
      display={"flex"}
      gap={"2rem"}
      sx={{ bgcolor: theme.palette.grey.A700 }}
    >
      {isFull && (
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            mt: { xs: 0, md: 2 },
            pt: 8,
            pb: { xs: 2.5, md: 10 },
            "&:after": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "80%",
              bottom: 0,
              left: 0,
              background:
                theme.direction === ThemeDirection.RTL
                  ? `linear-gradient(transparent 100%, rgb(31, 31, 31) 70%)`
                  : `linear-gradient(180deg, transparent 0%, ${theme.palette.grey.A700} 70%)`,
            },
          }}
        >
          <Container>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{ position: "relative", zIndex: 1 }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      pr: 0,
                      textAlign: "left",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <LogoSection contrast={true} darkBackground={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      <Box sx={{ pt: isFull ? 0 : 10, pb: 10 }}>
        <Container>
          {/* <Grid container spacing={2}> */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    About us
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink
                      href="https://www.google.com/maps/place/Dominic+Waterfield+Building+Services/@52.888637,-1.6701008,9z/data=!4m6!3m5!1s0x848e328deacd989b:0xe62594c0917eea40!8m2!3d53.1183755!4d-1.0054279!16s%2Fg%2F11v_9qgkfz?entry=ttu"
                      target="_blank"
                      underline="none"
                    >
                      Melton road
                    </FooterLink>
                    <FooterLink
                      href="https://www.google.com/maps/place/Dominic+Waterfield+Building+Services/@52.888637,-1.6701008,9z/data=!4m6!3m5!1s0x848e328deacd989b:0xe62594c0917eea40!8m2!3d53.1183755!4d-1.0054279!16s%2Fg%2F11v_9qgkfz?entry=ttu"
                      target="_blank"
                      underline="none"
                    >
                      West Bridgford
                    </FooterLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    Company
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink
                      href="https://www.facebook.com/DominicWaterfieldBuildingServicesLtd/"
                      target="_blank"
                      underline="none"
                    >
                      Careers
                    </FooterLink>

                    <FooterLink
                      href="https://www.facebook.com/DominicWaterfieldBuildingServicesLtd/"
                      target="_blank"
                      underline="none"
                    >
                      Facebook
                    </FooterLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    Help
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink
                      component={RouterLink}
                      to="/contact"
                      underline="none"
                      sx={{
                        ":hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      FAQ
                    </FooterLink>
                    <FooterLink
                      component={RouterLink}
                      to="/contact"
                      underline="none"
                      sx={{
                        ":hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Contact form
                    </FooterLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    Partners
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink underline="none">Landscaping</FooterLink>
                    <FooterLink underline="none">Decorating</FooterLink>
                    <FooterLink underline="none">Architecture</FooterLink>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Container>
      </Box>
    </Stack>
  );
};

export default FooterBlock;
