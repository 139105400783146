import { useState } from "react";

// material-ui
import { IconButton, Box, Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";
import { menu } from "./menu";
import { Link } from "react-router-dom";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderMobile = () => {
  const theme = useTheme();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    // Prevent default behavior for touch events
    if (event.type === "touchstart" || event.type === "touchend") {
      event.preventDefault();
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        maxWidth: "100vw",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu.map((item, index) => (
          <ListItem key={item.label}>
            <Button
              sx={{
                width: "100%",
                ":hover": {
                  color: theme.palette.primary.main,
                },
              }}
              variant="subtle"
              to={item.to}
              component={Link}
            >
              {item.label}
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        aria-label="menu burger"
        aria-controls={state.top ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={toggleDrawer("top", true)}
        color="secondary"
        variant="light"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={"top"}
        open={state["top"]}
        onClose={toggleDrawer("top", false)}
        // ModalProps={{
        //   disableScrollLock: true, // Prevents adding padding-right to the body
        // }}
      >
        {list("top")}
      </Drawer>
    </>
  );
};

export default HeaderMobile;
