// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function Modal() {
  return {
    MuiModal: {
      defaultProps: {
        disableScrollLock: true, // Disable scroll lock globally
      },
    },
  };
}
