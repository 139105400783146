import { Outlet } from "react-router-dom";
// material-ui
import { Box, Divider } from "@mui/material";

// project import
import Header from "../Header";
import FooterBlock from "layout/Footers/FooterBlock";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Outlet />
      </Box>
      <FooterBlock isFull={true} />
    </Box>
  );
};

export default MainLayout;
